import Image from "next/image";
import TradeVolume from "./TradeVolume";

const Intro = () => {
  return (
    // <!-- Intro -->
    <section className="bg-gradient-to-r from-[transparent_33%] to-[#F5F8FA_33%] py-36 dark:to-[#101436_33%]">
      <div className="container">
        <div className="lg:flex lg:justify-between">
          {/* <!-- Image --> */}
          <div className="relative lg:w-[45%]">
            <figure className="relative">
              <img
                src="/images/dao/intro.jpg"
                className="rounded-2.5xl"
                alt="web protocol"
              />
            </figure>
          </div>

          {/* <!-- Info --> */}
          <div className="py-10 lg:w-[55%] lg:pl-24">
            <h2 className="mb-6 font-display text-3xl text-jacarta-700 dark:text-white">
            We are together creating the Unicorn of the future.
            </h2>
            <p className="mb-8 text-lg leading-normal dark:text-jacarta-300">
            4 Next Unicorn is a community that brings together entrepreneurs with big ideas and a relentless drive to bring their ideas to life. We aim to unleash the Unicorn of the future by providing visionary solutions in all the areas necessary for a venture to succeed.
            </p>
            <p className="dark:text-jacarta-300">
            We are a community of entrepreneurs and investors focused on creating the next Unicorn, equipped with the expertise needed in every aspect of a successful venture.
            </p>
            <TradeVolume />
          </div>
        </div>
      </div>
    </section>
    // <!-- end intro -->
  );
};

export default Intro;
