import React, { useEffect, useState } from "react";
import Benifits from "../../components/dao/Benifits";
import Intro from "../../components/dao/Intro";
import Participate from "../../components/dao/Participate";
import NewseLatter2 from "../../components/dao/newseLatter2";
import { Team } from "../../components/component";
import HomeHero from "../../components/hero/hero";
import Meta from "../../components/Meta";
import TrustedPartner from "../../components/dao/TrustedPartner";


const HomePage = () => {
  useEffect(() => {
    const header = document.querySelector("header");
    header.classList.add("bg-white/[.15]");
  }, []);

  return (
    <>
      <Meta title="4 Next Unicorn" />
      <HomeHero />
     
      <Team />
      <Intro />
      <Benifits />
      <Participate />
     
      <NewseLatter2 bgWhite={false} />
    </>
  );
};

export default HomePage;
