import Faq from "./Faq";
import VideoBlock from "./VideoBlock";

const Participate = () => {
  return (
    <>
      {/* <!-- Video / FAQ --> */}
      <section className="bg-light-base py-24 dark:bg-jacarta-800">
        <div className="container">
          <div className="mx-auto mb-12 max-w-xl text-center">
            <h2 className="mb-6 text-center font-display text-3xl font-medium text-jacarta-700 dark:text-white">
              How to Participate
            </h2>
            <p className="text-lg dark:text-jacarta-300">
            To apply for membership in the 4 Next Unicorn community, you can complete the application form based on the participation type that suits your preferences.
            </p>
          </div>
          <div className="lg:flex lg:flex-nowrap">
            <div className="lg:w-[59%]">
              <VideoBlock />
            </div>
            {/* End VideoBlock */}

            <div className="lg:w-[41%] lg:pl-24">
              <p className="mb-6 dark:text-jacarta-300">
              The 4 Next Unicorn community serves as a bridge between entrepreneurs and investors, supporting innovation and providing investment opportunities. It creates a fair ecosystem with features like democratic voting and revenue sharing. By offering team support to entrepreneurs, it enables brand identity formation and growth opportunities.
              </p>
              <a
                href="#"
                className="mb-8 inline-block text-sm font-bold text-accent"
              >
                Learn More
              </a>
              <Faq />
            </div>
            {/* End Faq */}
          </div>
        </div>
      </section>
      {/* <!-- end video / faq -->   */}
    </>
  );
};

export default Participate;
