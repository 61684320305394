/* eslint-disable react/no-unescaped-entities */
import { useState } from "react";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";

function Icon({ id, open }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`${
        id === open ? "rotate-180" : ""
      } h-4 w-4 transition-transform`}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
    </svg>
  );
}

const itemContent = [
  {
    id: 1,
    title: "What is the cryptocurrency owned by the 4 Next Unicorn community? ",
    descriptions: `The cryptocurrency owned by the 4 Next Unicorn community is called "NXTU". This cryptocurrency is used among community members for investments and transactions.`,
  },
  {
    id: 2,
    title: "What are the use cases of NXTU?",
    descriptions: `NXTU is used for project financing and investment transactions within the community. It can also serve as a means of payment among community members.`,
  },
  {
    id: 3,
    title: "How can I become a holder of NXTU?",
    descriptions: `To become a holder of NXTU, you need to join the 4 Next Unicorn community. Once you become a member, you can purchase NXTU or earn it through participation in specific activities.`,
  },
  {
    id: 4,
    title: "How is the value of NXTU determined?",
    descriptions: `The value of NXTU is determined by factors such as supply and demand as well as market conditions. The growth of the community and the interest in projects can influence the value of NXTU.`,
  },
  {
    id: 5,
    title: "What are the advantages of NXTU?",
    descriptions: `NXTU offers the convenience of conducting investments and transactions within the community. NXTU holders can also benefit from opportunities within the community and have the chance to participate in the success of projects.`,
  },
];

const FaqAccordion = () => {
  const [open, setOpen] = useState(0);

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };

  return (
    <div className="mb-14">
      {itemContent.map((item) => (
        <Accordion
          className="accordion-item mb-5 overflow-hidden rounded-lg border border-jacarta-100 dark:border-jacarta-600"
          key={item.id}
          open={open === item.id}
          icon={<Icon id={item.id} open={open} />}
        >
          <AccordionHeader
            className="accordion-button collapsed relative flex w-full items-center justify-between bg-white px-4 py-3 text-left font-display text-sm text-jacarta-700 dark:bg-jacarta-700 dark:text-white"
            onClick={() => handleOpen(item.id)}
          >
            {item.title}
          </AccordionHeader>
          <div className="hidden-style">
            <AccordionBody className="accordion-body border-t border-jacarta-100 bg-white p-4 dark:border-jacarta-600 dark:bg-jacarta-700">
              {item.descriptions}
            </AccordionBody>
          </div>
        </Accordion>
      ))}
    </div>
  );
};

export default FaqAccordion;
