import BeniftContent from "./BeniftContent";

const Benifits = () => {
  return (
    <>
      {/* <!-- Benefits --> */}
      <section className="py-24 dark:bg-jacarta-900">
        <div className="container">
          <div className="mx-auto mb-16 max-w-xl text-center">
            <h2 className="mb-6 text-center font-display text-3xl font-medium text-jacarta-700 dark:text-white">
            How Can 4 Next Unicorn Benefit to Ecosystem?
            </h2>
            <p className="text-lg dark:text-jacarta-300">
              At 4 Next Unicorn, the key shared characteristic of the projects we develop and support is their ability to integrate seamlessly. This enables each project to leverage the collective strength of our community and benefit from each other&apos;s success. 
            </p>
          </div>
          <BeniftContent />
        </div>
      </section>
      {/* <!-- end benefits --> */}
    </>
  );
};

export default Benifits;
